import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { DtPicker } from 'react-calendar-datetime-picker';
import DataTable from 'react-data-table-component';

import JSZip from 'jszip';

import { Method, Service } from 'shared-components/configuration';
import { BATCH_QUERY, PAYOUTS_QUERY } from 'shared-components/queries/graphql';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { CREATE_BATCH } from 'shared-components/queries/mutations';
import { generateLbFileContent } from 'shared-components/utils';

import style from './style.module.scss';

import 'react-calendar-datetime-picker/dist/style.css';

const Withdrawals = () => {
  const date = new Date();
  const yesterday = new Date();
  yesterday.setDate(date.getDate() - 1);
  const [dateRange, setDateRange] = useState<any>({});
  const [batches, setBatches] = useState<any>([]);
  const [lbData, setLbData] = useState<any>([]);
  const [buttonLock, setButtonLock] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [perPage, setPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handlePageChange = (page: number) => {
    setLoading(true);
    const totalPages = Math.ceil(batches.totalCount / perPage);
    if (page === 1) {
      batchesQuery.execute({ variables: { first: perPage } });
    } else if (page >= totalPages) {
      batchesQuery.execute({ variables: { last: perPage } });
    } else if (page > currentPage) {
      batchesQuery.execute({ variables: { first: perPage, after: batches.pageInfo.endCursor } });
    } else if (page < currentPage) {
      batchesQuery.execute({ variables: { last: perPage, before: batches.pageInfo.startCursor } });
    } else {
      batchesQuery.execute({ variables: { first: perPage, after: batches.pageInfo.endCursor } });
    }
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, _: any) => {
    setPerPage(newPerPage);
  };

  useEffect(() => {
    setTotalRows(batches.totalCount);
    setLoading(false);
  }, [batches]);

  const payoutsQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'payouts',
    data: {
      query: PAYOUTS_QUERY
    },
    auto: false,
    onResponse: (data: any) => {
      const payoutsData = data?.edges?.map((value: any) => {
        const values = value.node;
        return values;
      });

      setLbData(payoutsData);
    }
  });

  const batchesQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'batches',
    data: {
      query: BATCH_QUERY
    },
    auto: false,
    onResponse: (data: any) => {
      const batchesData = data?.edges?.map((value: any) => {
        const values = value.node;
        let amount = 0;
        values.payouts = values.payouts?.edges?.map((pvalue: any) => {
          amount = amount + pvalue.node.amount;
          return pvalue.node;
        });
        values.amount = amount;
        return values;
      });
      setBatches(batchesData);
      console.log(batchesData);
    }
  });

  const createBatchQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'createBatch',
    data: {
      query: CREATE_BATCH
    },
    auto: false,
    onResponse: (_data: any) => {
      batchesQuery.execute();
      payoutsQuery.execute();
      setButtonLock(false);
    }
  });

  const handleBatchCreate = useCallback(() => {
    setButtonLock(true);
    const yearFrom = dateRange.from.year.toString();
    const yearTo = dateRange.to.year.toString();
    const monthFrom = dateRange.from.month.toString().padStart(2, '0');
    const monthTo = dateRange.to.month.toString().padStart(2, '0');
    const dayFrom = dateRange.from.day.toString().padStart(2, '0');
    const dayTo = dateRange.to.day.toString().padStart(2, '0');
    const dateFrom = `${yearFrom}-${monthFrom}-${dayFrom}T00:00:00Z`;
    const dateTo = `${yearTo}-${monthTo}-${dayTo}T23:59:59Z`;
    createBatchQuery.execute({
      variables: {
        startTime: dateFrom,
        endTime: dateTo
      }
    });
  }, [createBatchQuery, dateRange]);

  const handleLBFile = useCallback((row: any) => {
    const date = new Date();
    const year = date.getFullYear();
    const tdyear = String(date.getFullYear()).slice(-2);
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const _formattedDate = `${year}${month}${day}`;
    const tdformattedDate = `${tdyear}${month}${day}`;

    const merchantIban = row.payouts[0].merchant.iban;

    const lbFileContent = generateLbFileContent(
      merchantIban, // Merchant's IBAN for header
      tdformattedDate, // Example creation date
      'LEVERANTÖRSBETALNINGAR', // Product name
      'GENAST', // Payment date
      'SEK', // Currency code
      row.payouts.map((data: any) => ({
        iban: data.clientIban, // Use client IBAN if available, otherwise use merchant IBAN
        internalReference: data.paymentReference, // Use payment reference as the internal reference
        ocrReferens: data.paymentReference, // Using payment reference as OCR reference
        amount: data.amount,
        paymentDate: 'GENAST', // Immediate payment
        infoToSender: data.paymentReference // Optional additional information (using payment reference here)
      }))
    );
    const zip = new JSZip();
    let counter = 1;
    for (const value of lbFileContent) {
      zip.file(`${row.batchId}-${counter}.lb`, value);
      counter = counter + 1;
    }

    zip.generateAsync({ type: 'blob' }).then(function (content) {
      const blob = new Blob([content], { type: 'application/zip' });
      const link = document.createElement('a');
      link.download = `${row.batchId}-${row.createdAt}.zip`;
      link.href = URL.createObjectURL(blob);
      document.body.append(link);
      link.click();
      link.remove();
    });
  }, []);

  useEffect(() => {
    handlePageChange(currentPage);
    payoutsQuery.execute();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Row>
        <Col>
          <Card className={style.card}>
            <Card.Title>Batches</Card.Title>
            <Card.Text>
              <DataTable
                columns={[
                  {
                    name: 'Created at',
                    selector: (row: any) => row?.['createdAt']
                  },
                  {
                    name: 'Batch ID',
                    selector: (row: any) => row?.['batchId']
                  },
                  {
                    name: 'Amount',
                    selector: (row: any) => `${row?.['amount'].toFixed(2)} SEK`
                  },
                  {
                    name: 'Action',
                    cell: (row: any) => (
                      <>
                        <Button
                          onClick={() => {
                            handleLBFile(row);
                          }}
                        >
                          Download LB Files
                        </Button>
                      </>
                    )
                  }
                ]}
                data={batches}
                responsive={true}
                highlightOnHover={true}
                progressPending={loading}
                pagination
                paginationPerPage={30}
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
              />
            </Card.Text>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className={style.card}>
            <Card.Title>
              <div style={{ display: 'flex' }}>Unhandled withdrawals</div>
            </Card.Title>
            <Card.Text>
              <DataTable
                columns={[
                  {
                    name: 'Created at',
                    selector: (row: any) => `${row?.['createdAt'].replace('T', ' ')}`
                  },
                  {
                    name: 'Payout reference',
                    selector: (row: any) => row?.['paymentReference']
                  },
                  {
                    name: 'IBAN',
                    selector: (row: any) => row?.['clientIban']
                  },
                  {
                    name: 'Amount',
                    selector: (row: any) => `${row?.['amount'].toFixed(2)} ${row?.['currency'].toUpperCase()}`
                  }
                ]}
                data={lbData}
                pagination
              />
              <div style={{ display: 'flex' }}>
                <Button onClick={handleBatchCreate} disabled={buttonLock}>
                  Create Batch
                </Button>
                <div style={{ width: '300px', alignSelf: 'center' }}>
                  <DtPicker
                    type="range"
                    placeholder="select date"
                    showWeekend
                    todayBtn
                    initValue={{
                      from: {
                        year: yesterday.getFullYear(),
                        month: yesterday.getMonth() + 1,
                        day: yesterday.getDate()
                      },
                      to: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
                    }}
                    onChange={(date: any) => {
                      setDateRange(date);
                    }}
                  />
                </div>
              </div>
            </Card.Text>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Withdrawals;
