import { Button, FloatingLabel, Form, InputGroup } from 'react-bootstrap';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as jose from 'jose';

import CryptoKeysModal from 'components/Modals/CryptoKeysModal/cryptoKeysModal';
import PublicKeyPendingModal from 'components/Modals/PublicKeyPending';
import PublicKeyCompleteModal from 'components/Modals/PublicKeyComplete';
import { AdminUrl, MerchantPrefix, Method, Service, autheidServiceUrl } from 'shared-components/configuration';
import PublicKeyCancelledModal from 'components/Modals/PublicKeyCancelled';
import { useMerchantInfo } from 'shared-components/providers/MerchantInfoProvider';
import CopyButton from 'shared-components/components/CopyButton';
import useSWQuery from 'shared-components/hooks/useSWQuery';

import styles from './style.module.scss';

const ApiKeysAdd = () => {
  const merchantInfo = useMerchantInfo();
  const [cryptoKeysModalVisible, setCryptoKeysModalVisible] = useState(false);
  const [publicKeyPendingVisible, setPublicKeyPendingVisible] = useState(false);
  const [publicKeyCancelledVisible, setPublicKeyCancelledVisible] = useState(false);
  const [publicKeyCompleteVisible, setPublicKeyCompleteVisible] = useState(false);
  const [privateKey, setPrivateKey] = useState<string>();
  const [publicKey, setPublicKey] = useState<string>();

  const addKeyQuery = useSWQuery({
    service: Service.AUTHEID,
    method: Method.QUERY,
    returnObjectName: 'login',
    data: {
      api: 'login',
      method: 'upload_key_init'
    },
    auto: false,
    onResponse: (data: any) => {
      const method = data.method;
      if (method !== 'upload_key_init' && method !== 'upload_key_complete' && method !== 'upload_key_status') {
        return;
      }
      const status = data.data.status;
      if (status === 'PENDING') {
        setPublicKeyCancelledVisible(false);
        setPublicKeyCompleteVisible(false);
        setPublicKeyPendingVisible(true);
      }
      if (status === 'USER_CANCELLED') {
        setPublicKeyCompleteVisible(false);
        setPublicKeyPendingVisible(false);
        setPublicKeyCancelledVisible(true);
      }
      if (status === 'SUCCESS') {
        setPublicKeyCancelledVisible(false);
        setPublicKeyPendingVisible(false);
        setPublicKeyCompleteVisible(true);
      }
    }
  });

  const navigate = useNavigate();
  const handleSubmit = useCallback(
    async (event: any) => {
      event.preventDefault();
      const publicKey = event.currentTarget.elements.key.value;
      const key = await jose.importSPKI(publicKey.toString(), 'ES256', {
        extractable: true
      });
      const jwkkey = await jose.exportJWK(key);
      addKeyQuery.execute({
        args: { email: merchantInfo.email, user_cert: jwkkey, service_url: autheidServiceUrl }
      });
    },
    [addKeyQuery, merchantInfo.email]
  );
  /*
  useEffect(function setupListener() {
    function handleListener(event: MessageEvent<any>) {
      if (
        event.data.system === 'autheid' &&
        event.data.method === 'upload_key_init' &&
      if (
        event.data.system === 'autheid' &&
        event.data.method === 'upload_key_status' &&

      if (
        event.data.system === 'autheid' &&
        event.data.method === 'upload_key_complete' &&
    }
    App.channel.addEventListener('message', handleListener);

    return function cleanupListener() {
      App.channel.removeEventListener('message', handleListener);
    };
  }, []);
*/
  const handleCryptoKeysGenerate = useCallback(async () => {
    const { publicKey, privateKey } = await jose.generateKeyPair('ES256', {
      extractable: true
    });
    const pubKey = await jose.exportSPKI(publicKey);
    const prvKey = await jose.exportPKCS8(privateKey);
    setPrivateKey(prvKey);
    setPublicKey(pubKey);
  }, []);

  return (
    <>
      <PublicKeyCompleteModal
        visible={publicKeyCompleteVisible}
        handleClose={() => {
          setPublicKeyCompleteVisible(false);
          navigate(`${MerchantPrefix}/${AdminUrl.API_KEYS}`);
        }}
      />
      <PublicKeyPendingModal
        visible={publicKeyPendingVisible}
        handleClose={() => {
          setPublicKeyPendingVisible(false);
        }}
      />
      <PublicKeyCancelledModal
        visible={publicKeyCancelledVisible}
        handleClose={() => {
          setPublicKeyCancelledVisible(false);
        }}
      />
      <CryptoKeysModal
        visible={cryptoKeysModalVisible}
        handleClose={() => {
          setCryptoKeysModalVisible(false);
        }}
      />

      <Form onSubmit={handleSubmit}>
        <InputGroup className="mt-3">
          <FloatingLabel controlId="apikeysKey" label="Public key">
            <Form.Control
              autoFocus
              name="key"
              as="textarea"
              type="textarea"
              placeholder="Key"
              rows={5}
              size="sm"
              aria-rowcount={5}
              required
              style={{ height: '150px' }}
              value={publicKey}
            />
          </FloatingLabel>
        </InputGroup>
        <InputGroup className="mt-3">
          {privateKey != null && privateKey?.length > 5 && (
            <FloatingLabel
              controlId="apikeysKey"
              label="Private Key - please copy that to file and keep it safe"
              className="mt-3 flex-1 position-relative"
            >
              <Form.Control
                autoFocus
                name="privateKey"
                as="textarea"
                type="textarea"
                placeholder="Private Key"
                rows={5}
                size="sm"
                aria-rowcount={5}
                style={{ height: '150px' }}
                value={privateKey}
                readOnly
              />
              <CopyButton value={privateKey} className={styles.copyButton} />
            </FloatingLabel>
          )}
        </InputGroup>
        <div className={styles.buttons}>
          <Button type="submit">Submit public key</Button>
          <Button onClick={handleCryptoKeysGenerate} variant="success">
            Generate new API Key
          </Button>
        </div>
      </Form>
    </>
  );
};
export default ApiKeysAdd;
